import React from 'react';
import './StickerTable.css';
import Loader from '../Loader/Loader';
import Api from "../../../js/Api";
import { List, ListItem, Paper,  Box } from '@mui/material';


const Client = new Api();

function handleReprint(stickerId){
    Client.reprintSticker(stickerId).then((result)=>{
        console.log(result);
    })
}

function StickerList({ stickers }) {
    return (
        <Paper style={{ maxWidth: '90vw', minWidth: '80vw', margin: 'auto', padding: '1rem', width:'90%', backgroundColor:'#FCFBF4' }}>
            <List>
                {stickers.map((sticker,index) => (
                    <ListItem key={`${index}-${sticker.id}`} divider>
                        <Box>
                        <p>Order: <b>{sticker.orderNumber}</b></p>
                        <p>Name: <b>{sticker.name}</b></p>
                        {sticker.customerName ? 
                        <p>Customer Name: <b>{sticker.customerName}</b></p> : null }
                        {sticker.topping1 ? <p>Topping1: <b>{sticker.topping1}</b></p> : null}
                        {sticker.topping2 ? <p>Topping2: <b>{sticker.topping2}</b></p> : null}
                        {sticker.customizations ? sticker.customizations.map((cust)=>{
                            return <p>{cust.name}: <b>{cust.value}</b></p>
                        }) : null}
                        <button onClick={()=>{
                            handleReprint(sticker.id);
                        }}>Reprint</button>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

class StickerTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
           sortMode: 'count'
        }
    }



render(){
    const { data, title, loading,skipFlavourBreakdown, showLargeAndSmall } = this.props;
    const { sortMode } = this.state;
 
    if(data.length <= 0){
        return null;
    }
    
    return <div className='stickerTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3>{title}</h3>
            </div>            
            { data.length > 0 ? 
                <StickerList stickers={data}/> : <p>There is No Data</p>
            } 
        </React.Fragment> }
        </div>
}

}

export default StickerTable;